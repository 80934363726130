import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import axios from 'axios';
import './style.css';

const SendMessageAlert = forwardRef(({ subject, message, to, site }, ref) => {
  const [open, setOpen] = useState(false);
  const [sended, setSended] = useState(null);
  const [toSend, setToSend] = useState(null);
  //   useEffect(() => {
  //     if (sended !== null) {
  //     }
  //   }, [sended]);
  //   useEffect(() => {
  //     if (send === true && toSend) {
  //       sendEmail();
  //       setOpen(true);
  //     }
  //   }, [send]);

  const close = () => {
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };
  useImperativeHandle(ref, () => ({
    sendEmail() {
      setSended(null);
      setOpen(true);
      axios
        .post('http://oobsender.oobdigital.com.br/send', {
          to: to,
          site: site,
          subject: subject,
          text: message,
        })
        .then((response) => {
          console.log('response', response);
          setSended(true);
        })
        .catch((err) => {
          setSended(false);

          console.log('error', err);
        })
        .finally(() => {
          close();
        });
    },
  }));

  return (
    <>
      {open == true && (
        <>
          <div
            style={{
              zIndex: 9,
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              opacity: 0.7,
              backgroundColor: '#000',
            }}
          />
          <div
            style={{
              zIndex: 99,
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: 8,
                backgroundColor: '#fff',
                padding: 50,
                color: '#272727',
                fontSize: 30,
              }}
            >
              {sended == null && (
                <div class="windows8">
                  <div class="wBall" id="wBall_1">
                    <div class="wInnerBall"></div>
                  </div>
                  <div class="wBall" id="wBall_2">
                    <div class="wInnerBall"></div>
                  </div>
                  <div class="wBall" id="wBall_3">
                    <div class="wInnerBall"></div>
                  </div>
                  <div class="wBall" id="wBall_4">
                    <div class="wInnerBall"></div>
                  </div>
                  <div class="wBall" id="wBall_5">
                    <div class="wInnerBall"></div>
                  </div>
                </div>
              )}
              {sended == true && (
                <div style={{ color: '#7FBA23' }}>Mensagem enviada.</div>
              )}
              {sended == false && (
                <div style={{ color: '#CC5D5F' }}>
                  Erro ao enviar a mesagem tente novamente.
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default SendMessageAlert;
