import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/BwHelderDEMO-W1Bold.otf';
import './fonts/BwHelderDEMO-W1ExtraBold.otf';
import './fonts/BwHelderDEMO-W1Light.otf';
import './fonts/BwHelderDEMO-W1Medium.otf';
import './fonts/BwHelderDEMO-W1Regular.otf';
import './fonts/BwHelderDEMO-W1Thin.otf';
import './fonts/BwHelderDEMO-W2Bold.otf';
import './fonts/BwHelderDEMO-W2ExtraBold.otf';
import './fonts/BwHelderDEMO-W2Light.otf';
import './fonts/BwHelderDEMO-W2Medium.otf';
import './fonts/BwHelderDEMO-W2Regular.otf';
import './fonts/BwHelderDEMO-W2Thin.otf';
import './fonts/BwHelderDEMO-W3Bold.otf';
import './fonts/BwHelderDEMO-W3ExtraBold.otf';
import './fonts/BwHelderDEMO-W3Light.otf';
import './fonts/BwHelderDEMO-W3Medium.otf';
import './fonts/BwHelderDEMO-W3Regular.otf';
import './fonts/BwHelderDEMO-W3Thin.otf';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
