import React, { useState, useRef } from 'react';
import './App.css';
import SendMessageAlert from './components/SendMessageAlert';
import Scroll from 'react-scroll';
import { Document, Page, pdfjs } from 'react-pdf';
import cartao from './pdfs/cartao.pdf';
import ImageMapper from 'react-img-mapper';
const scroll = Scroll.animateScroll;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [tel, setTel] = useState('');
  const sendRef = useRef();
  console.log('window.location.href', window.location);

  if (window.location.pathname == '/cartao') {
    return (
      <div style={{}}>
        <img
          width={2479 / 2}
          style={{ height: 3506 / 2 }}
          src={require('./imgs/cartao.png')}
        />
        <a
          name="text-sorrisos"
          style={{
            width: 910,
            height: 100,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 160,
            marginTop: 430,
          }}
          href="https://providerbio-latam.invisalign.com/sv/1303620"
        ></a>
        <a
          name="icon-whatsapp"
          style={{
            width: 100,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 105,
            marginTop: 1140,
          }}
          href="https://bit.ly/316UeT4"
        ></a>
        <a
          name="icon-facebook"
          style={{
            width: 100,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 235,
            marginTop: 1140,
          }}
          href="https://www.facebook.com/sorriraodontostudio"
        ></a>
        <a
          name="icon-instagram"
          style={{
            width: 100,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 362,
            marginTop: 1140,
          }}
          href="https://www.instagram.com/sorriraodontostudio/"
        ></a>

        <a
          name="icon-telefone"
          style={{
            width: 110,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 482,
            marginTop: 1140,
          }}
          href="tel:2230313100"
        ></a>
        <a
          name="icon-salvar-contato"
          style={{
            width: 130,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 610,
            marginTop: 1140,
          }}
          href="http://encurtador.com.br/fEJLS"
        ></a>
        <a
          name="icon-localizacao"
          style={{
            width: 110,
            height: 140,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 760,
            marginTop: 1125,
          }}
          href="https://goo.gl/maps/HnLkHp7cAAmp8B6C7"
        ></a>
        <a
          name="icon-email"
          style={{
            width: 112,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 898,
            marginTop: 1140,
          }}
          href="mailto:contato@sorrira.com.br"
        ></a>
        <a
          name="icon-site"
          style={{
            width: 105,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 1030,
            marginTop: 1140,
          }}
          href="http://sorrira.com.br/"
        ></a>
        <a
          name="baner-invisalign"
          style={{
            width: 750,
            height: 162,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 228,
            marginTop: 1342,
          }}
          href="https://providerbio-latam.invisalign.com/sv/1303620"
        ></a>
      </div>
    );
  } else {
    return (
      <div style={{}}>
        <img
          width={2479 / 2}
          style={{ height: 3506 / 2 }}
          src={require('./imgs/cartao.png')}
        />
        <a
          name="text-sorrisos"
          style={{
            width: 910,
            height: 100,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 160,
            marginTop: 430,
          }}
          href="https://providerbio-latam.invisalign.com/sv/1303620"
        ></a>
        <a
          name="icon-whatsapp"
          style={{
            width: 100,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 105,
            marginTop: 1140,
          }}
          href="https://bit.ly/316UeT4"
        ></a>
        <a
          name="icon-facebook"
          style={{
            width: 100,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 235,
            marginTop: 1140,
          }}
          href="https://www.facebook.com/sorriraodontostudio"
        ></a>
        <a
          name="icon-instagram"
          style={{
            width: 100,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 362,
            marginTop: 1140,
          }}
          href="https://www.instagram.com/sorriraodontostudio/"
        ></a>

        <a
          name="icon-telefone"
          style={{
            width: 110,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 482,
            marginTop: 1140,
          }}
          href="tel:2230313100"
        ></a>
        <a
          name="icon-salvar-contato"
          style={{
            width: 130,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 610,
            marginTop: 1140,
          }}
          href="http://encurtador.com.br/fEJLS"
        ></a>
        <a
          name="icon-localizacao"
          style={{
            width: 110,
            height: 140,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 760,
            marginTop: 1125,
          }}
          href="https://goo.gl/maps/HnLkHp7cAAmp8B6C7"
        ></a>
        <a
          name="icon-email"
          style={{
            width: 112,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 898,
            marginTop: 1140,
          }}
          href="mailto:contato@sorrira.com.br"
        ></a>
        <a
          name="icon-site"
          style={{
            width: 105,
            height: 130,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 1030,
            marginTop: 1140,
          }}
          href="http://sorrira.com.br/"
        ></a>
        <a
          name="baner-invisalign"
          style={{
            width: 750,
            height: 162,
            opacity: 0.5,
            position: 'absolute',
            marginLeft: 228,
            marginTop: 1342,
          }}
          href="https://providerbio-latam.invisalign.com/sv/1303620"
        ></a>
      </div>
    );
    // return (
    //   <div className="App">
    //     <SendMessageAlert
    //       ref={sendRef}
    //       subject={`${name} - ${email} - ${tel}`}
    //       message={message}
    //       to="rmdebarros@gmail.com"
    //       site="Sorrirá"
    //     />
    //     <div
    //       className="content"
    //       style={{ position: 'absolute', zIndex: 2, top: 800 }}
    //     >
    //       <img
    //         className="ativo"
    //         style={{
    //           transform: 'scaleX(-1)',
    //           marginTop: -155,
    //         }}
    //         src={require('./imgs/ativo.png')}
    //       />
    //     </div>
    //     <div
    //       className="content"
    //       style={{
    //         position: 'absolute',
    //         zIndex: 2,
    //         top: 1125,
    //         flexDirection: 'column',
    //         alignItems: 'flex-end',
    //       }}
    //     >
    //       <img className="ativo" src={require('./imgs/ativo.png')} />
    //     </div>

    //     <div className="header container">
    //       <div
    //         className="content"
    //         style={{
    //           alignItems: 'center',
    //           marginTop: 20,
    //           flexDirection: 'column',
    //         }}
    //       >
    //         <img className="logo" src={require('./imgs/logo.png')} />
    //         <div className="menu">
    //           <div
    //             onClick={() => {
    //               scroll.scrollTo(0);
    //             }}
    //           >
    //             HOME
    //           </div>
    //           <div
    //             onClick={() => {
    //               scroll.scrollTo(830);
    //             }}
    //           >
    //             SOBRE NÓS
    //           </div>
    //           <div
    //             onClick={() => {
    //               scroll.scrollTo(1450);
    //             }}
    //           >
    //             SERVIÇOS
    //           </div>
    //           <div
    //             onClick={() => {
    //               scroll.scrollTo(2850);
    //             }}
    //           >
    //             DEPOIMENTOS
    //           </div>
    //           <div
    //             onClick={() => {
    //               scroll.scrollTo(3450);
    //             }}
    //           >
    //             EQUIPE
    //           </div>
    //           <div
    //             onClick={() => {
    //               scroll.scrollTo(4300);
    //             }}
    //           >
    //             CONTATO
    //           </div>
    //           <div
    //             onClick={() => {
    //               scroll.scrollTo(5000);
    //             }}
    //           >
    //             ENDEREÇO
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             position: 'relative',
    //             marginTop: -10,
    //             marginRight: 10,
    //             marginLeft: 'auto',
    //             alignItems: 'right',
    //           }}
    //         >
    //           <img style={{ height: 22 }} src={require('./imgs/iTero.png')} />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="banner">
    //       <div className="content">
    //         <div
    //           style={{
    //             position: 'absolute',
    //             marginTop: 20,
    //             marginLeft: 20,
    //           }}
    //         >
    //           <img style={{ width: 226 }} src={require('./imgs/invi.png')} />
    //         </div>
    //         <div className="banner-info">
    //           <div>Sorrisos criam conexões entre pessoas e nós</div>
    //           <div className="banner-title">valorizamos cada um</div>
    //           <div>
    //             <div className="banner-button">Agende sua consulta</div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="about-us">
    //       <div className="content" style={{ zIndex: 999 }}>
    //         <div className="about-box">
    //           <div className="about-title">Sobre nós</div>
    //           <div className="about-subtitle">
    //             Odontologia com qualidade e segurança
    //           </div>
    //           <div className="about-hr" />
    //           <div style={{ lineHeight: 1.4 }}>
    //             Voltar a sorrir revela a sua singularidade ao mundo. A Sorrirá é
    //             um Odonto <br />
    //             Studio que te devolve a liberdade de <br /> sorrir com os
    //             melhores tratamentos, realizados através de um atendimento
    //             cuidadoso e único como o seu sorriso.
    //           </div>
    //           <div>
    //             <div className="about-button">Agende sua consulta</div>
    //           </div>
    //         </div>
    //         <img
    //           style={{
    //             position: 'absolute',
    //             width: 550,
    //             height: 450,
    //             marginLeft: 655,
    //             marginTop: 103,
    //           }}
    //           src={require('./imgs/1676.jpg')}
    //         />
    //       </div>
    //     </div>
    //     <div className="services">
    //       <div
    //         className="content"
    //         style={{ zIndex: 1, flexDirection: 'column' }}
    //       >
    //         <div className="services-box">
    //           <div style={{ marginBottom: 40 }}>Nossos Serviços</div>
    //           <div className="service">Endodontia</div>
    //           <div className="service">Ortodontia</div>
    //           <div className="service">Implante</div>
    //           <div className="service">Prótese</div>
    //           <div className="service">Cirurgia</div>
    //           <div className="service-button">Agende sua Consulta</div>
    //         </div>
    //         <img
    //           style={{
    //             position: 'relative',
    //             width: '100%',
    //             height: 540,
    //             marginTop: -620,
    //           }}
    //           src={require('./imgs/teste.png')}
    //         />
    //       </div>
    //     </div>
    //     <div>
    //       <div className="content">
    //         <div className="clients">
    //           <div className="client-title">O que nossos clientes dizem</div>
    //           <div className="depos">
    //             <div className="depo">
    //               <div>
    //                 “É incrível como o ambiente e o atendimento fazem a
    //                 diferença, cheguei pela primeira vez ao Studio ansiosa e
    //                 preocupada, mas durante a espera fui bem atendida e toda a
    //                 equipe fez com que eu me senti especial, mesmo nunca tendo
    //                 ido lá antes. Isso me passou muita segurança.”
    //               </div>
    //               <div className="client-name">Lucia Ferreira</div>
    //               <div className="client-hr" />
    //             </div>
    //             <div className="depo">
    //               <div>
    //                 “Encontrar bom profissionais e que te passam confiança, não
    //                 é fácil. Ainda bem que eu encontrei a Dra. Bianca e o Dr.
    //                 Hugo, eles são excelentes, o resultado foi melhor do que eu
    //                 esperava e valeu a pena o investimento. Agora eu sorrio com
    //                 muito mais alegria! ”
    //               </div>
    //               <div className="client-name">José Vieira</div>
    //               <div className="client-hr" />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <div className="content">
    //         <div className="people-box">
    //           Somos feitos de pessoas,
    //           <br />
    //           para pessoas
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <div className="content">
    //         <div className="doctors-box">
    //           <div className="doctor">
    //             <img
    //               className="doctor-photo"
    //               src={require('./imgs/bianca.png')}
    //             />
    //             <div className="doctor-name">Dra. Bianca de Boni</div>
    //             <div className="doctor-hr" />
    //             <div className="doctor-description">
    //               CRO-RJ 30661
    //               <br />
    //               - Cirurgiã Dentista formada pela Universidade Estácio de Sá em
    //               2003.
    //               <br />
    //               - Especialização em Prótese Dentária pela UFJF em 2006.
    //               <br />
    //               - Especialização em Endodontia pela Odontoclínica de
    //               Aeronáutica Santos Dumont em 2014.
    //               <br />
    //               - Especialização em Ortodontia.
    //               <br />
    //             </div>
    //           </div>
    //           <div className="doctor">
    //             <img
    //               className="doctor-photo"
    //               src={require('./imgs/hugo.png')}
    //             />
    //             <div className="doctor-name">Dr. Hugo Lisboa</div>
    //             <div className="doctor-hr" />
    //             <div className="doctor-description">
    //               CRO-RJ 29161
    //               <br />
    //               - Cirurgião Dentista formado pela Universidade Gama Filho em
    //               2002.
    //               <br />
    //               - Especialização em Prótese Dentária pela Faculdade de
    //               Odontologia de Bauru em 2005.
    //               <br />
    //               - Especialização em Implantodontia pela ABO em 2017.
    //               <br />
    //               - Atualização em Cirurgia Oral Menor em 2018.
    //               <br />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <div className="content">
    //         <div className="contact">
    //           <div className="contact-box">
    //             <div className="contact-photo">
    //               <img src={require('./imgs/image_contato_e_agende.jpeg')} />
    //             </div>
    //             <div className="contact-form">
    //               <div className="contact-title">
    //                 Entre em contato
    //                 <br />e agende a sua consulta
    //               </div>
    //               <div className="contact-hr" />
    //               <div className="form-inputs">
    //                 <input
    //                   value={name}
    //                   type="text"
    //                   name="name"
    //                   placeholder="Nome e sobrenome"
    //                   onChange={(e) => {
    //                     setName(e.target.value);
    //                   }}
    //                 />
    //               </div>
    //               <div className="form-inputs">
    //                 <input
    //                   value={email}
    //                   type="text"
    //                   name="email"
    //                   placeholder="E-mail"
    //                   onChange={(e) => {
    //                     setEmail(e.target.value);
    //                   }}
    //                 />
    //                 <input
    //                   value={tel}
    //                   type="text"
    //                   name="tel"
    //                   placeholder="Telefone"
    //                   onChange={(e) => {
    //                     setTel(e.target.value);
    //                   }}
    //                 />
    //               </div>
    //               <div className="form-inputs">
    //                 <textarea
    //                   value={message}
    //                   placeholder="Sua mensagem"
    //                   onChange={(e) => {
    //                     setMessage(e.target.value);
    //                   }}
    //                 />
    //                 <div
    //                   className="send-button"
    //                   onClick={() => {
    //                     sendRef.current.sendEmail();
    //                   }}
    //                 >
    //                   Enviar
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <div className="content">
    //         <div className="footer">
    //           <div className="footer-logo">
    //             <img src={require('./imgs/logo.png')} />
    //             <div className="copyright">
    //               Copyright by &nbsp;<b>Sorrirá</b>&nbsp; 2019. All right
    //               reserved.
    //             </div>
    //           </div>
    //           <div className="footer-info">
    //             <div class="footer-title">
    //               <strong>TELEFONE</strong>
    //             </div>
    //             <div class="footer-detail">(22) 3031-3100</div>
    //             <div class="footer-title">
    //               <strong>HORÁRIO DE FUNCIONAMENTO</strong>
    //             </div>
    //             <div class="footer-detail">Segunda a Sexta de 9h às 19h</div>
    //             <div class="footer-title">
    //               <strong>SAC</strong>
    //             </div>
    //             <div class="footer-detail">
    //               Dúvidas, reclamações, sugestões e solicitações
    //             </div>
    //             <div className="socials">
    //               <a
    //                 target="_blank"
    //                 href="https://www.facebook.com/Sorrirá-Odonto-Studio-100399271583844/?modal=admin_todo_tour"
    //               >
    //                 <img src={require('./imgs/google.png')} />
    //               </a>
    //               <a
    //                 target="_blank"
    //                 href="https://www.facebook.com/Sorrirá-Odonto-Studio-100399271583844/?modal=admin_todo_tour"
    //               >
    //                 <img src={require('./imgs/whats.png')} />
    //               </a>
    //               <a
    //                 target="_blank"
    //                 href="https://www.facebook.com/Sorrirá-Odonto-Studio-100399271583844/?modal=admin_todo_tour"
    //               >
    //                 <img src={require('./imgs/face.png')} />
    //               </a>
    //               <a
    //                 href="https://instagram.com/sorriraodonto?igshid=fbyc5w068f9x"
    //                 target="_blank"
    //               >
    //                 <img src={require('./imgs/insta.png')} />
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default App;
